import { UserProvider } from './UserContext';
import { LoginProvider } from './LoginContext';
import { FiltersProvider } from './FiltersContext';

export function AppWrapper({ children }) {
  return (
    <UserProvider>
      <FiltersProvider>
        <LoginProvider>
          {children}
        </LoginProvider>
      </FiltersProvider>
    </UserProvider>
  );
}