import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom'
import { format } from 'date-fns';
import { getMemberships } from '../../../utils/membershipsApi';
import Title from '../../../components/Title';
import { IconButton } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

export default function MembershipsList() {
    const [memberships, setMemberships] = useState([])

    const navigate = useNavigate()

    const fetchData = async () => {
      const res = await getMemberships()
        if (res) setMemberships(res)
    }

    useEffect(() => {
      fetchData()
    }, []);

    return (
        <React.Fragment>
          <Title>Aziende</Title>

          <DataGrid
            autoHeight
            columns={[
              { field: 'company_name', headerName:'Azienda', width: 250, valueGetter: (params) => {
                  const {row} = params
                  return row.company.name
                }  
              },
              { field: 'status', headerName:'Status', width: 100,
                type: 'singleSelect',
                valueOptions: ['active', 'trialing', 'canceled', 'unpaid', 'incomplete_expired']
              },
              { field: 'date_end', headerName:'Date End', width: 100, 
                type: "date",
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return format(new Date(row.date_end), 'dd/MM/yyyy')
                }  
              },
              { field: 'membership', type:"boolean", headerName:'Membership', width: 100 },
              { field: 'detail', headerName:'', width: 50, 
                filterable: false,
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <IconButton variant="contained" onClick={() => navigate(`/memberships/${row.subscription_id}`)}>
                    <EditIcon />
                  </IconButton>
                } 
              }
            ]}
            getRowId={(row) => row.subscription_id}
            rows={memberships}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellClick={(e) => console.log(e)}
          />
        </React.Fragment>
      );
}
