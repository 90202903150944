
import { Routes, Route } from "react-router-dom";
import DocumentsList from '../pages/Documents/List'
import DocumentDetail from "../pages/Documents/Detail";
import SignIn from '../pages/Login';
import Dashboard from "../components/Dashboard";
import ConferenceList from "../pages/Conferences/List";
import ConferenceDetail from "../pages/Conferences/Detail";
import OrdersList from "../pages/Orders/List";
import UsersList from "../pages/Users/List";
import ExpertsList from "../pages/Experts/List";
import ExpertDetail from "../pages/Experts/Detail";
import Payments from "../pages/Payments";
import UserDetail from "../pages/Users/Detail";
import MembershipsList from "../pages/Memberships/List";
import MembershipDetail from "../pages/Memberships/Detail";
import OrderDetail from "../pages/Orders/Detail";
import ManualTransfersList from "../pages/ManualTransfers";
import IdarbList from "../pages/Idarb/List";
import IdarbDetail from "../pages/Idarb/Detail";
import DocumentArea from '../pages/DocumentArea/Detail';

export default function AppRoutes() {
    return <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="documents" element={<Dashboard title={'Documents'}><DocumentsList /></Dashboard>} />
        <Route path="document/:id" element={<Dashboard title={'Documents'}><DocumentDetail /></Dashboard>} />
        <Route path="document/new" element={<Dashboard title={'Documents'}><DocumentDetail /></Dashboard>} />
        <Route path="conferences" element={<Dashboard title={'Conferences'}><ConferenceList /></Dashboard>} />
        <Route path="conference/:id" element={<Dashboard title={'Conferences'}><ConferenceDetail /></Dashboard>} />
        <Route path="conference/new" element={<Dashboard title={'Conferences'}><ConferenceDetail /></Dashboard>} />
        <Route path="orders/:id" element={<Dashboard title={'Ordini'}><OrdersList /></Dashboard>} />
        <Route path="order/:orderId" element={<Dashboard title={'Ordini'}><OrderDetail /></Dashboard>} />
        <Route path="users" element={<Dashboard title={'Utenti'}><UsersList /></Dashboard>} />
        <Route path="users/:id" element={<Dashboard title={'Utenti'}><UserDetail /></Dashboard>} />
        <Route path="experts" element={<Dashboard title={'Esperti'}><ExpertsList /></Dashboard>} />
        <Route path="experts/:id" element={<Dashboard title={'Esperti'}><ExpertDetail /></Dashboard>} />
        <Route path="experts/new" element={<Dashboard title={'Esperti'}><ExpertDetail /></Dashboard>} />
        <Route path="payments" element={<Dashboard title={'Pagamenti'}><Payments /></Dashboard>} />
        <Route path="manual-transfers" element={<Dashboard title={'Bonifici'}><ManualTransfersList /></Dashboard>} />
        <Route path="arbitrators" element={<Dashboard title={'Arbitrators'}><IdarbList /></Dashboard>} />
        <Route path="arbitrators/:id" element={<Dashboard title={'Arbitrators'}><IdarbDetail /></Dashboard>} />
        <Route path="memberships" element={<Dashboard title={'Companies'}><MembershipsList /></Dashboard>} />
        <Route path="memberships/:id" element={<Dashboard title={'Companies'}><MembershipDetail /></Dashboard>} />
        {/* area documentale */}
        <Route path="document-area" element={<Dashboard title={'Area Documentale'}><DocumentArea /></Dashboard>} />
        {/* <Route path="document-area/:sectionId" element={<Dashboard title={'Area Documentale'}><DocumentArea /></Dashboard>} /> */}
    </Routes>
}