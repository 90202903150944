import axios from "axios"

export async function getIdarbList() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/arbitrators?all=true`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.arbitrators
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getIdarb(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/arbitrators/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.arbitrator
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function updateIdarb(arbitrator) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/arbitrators/${arbitrator.id}`, {
            method: "POST", // Should become PUT in production
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            data: {arbitrator}
        })
        if (res.data.status === "success") {
            return res.data
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function deleteIdarb(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/arbitrators/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function confirmIdarb(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/arbitrators/${id}/confirm`, {
            method: "POST", // Should become PUT in production
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}