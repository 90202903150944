import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getJudicialDocuments,
  deleteJudicialDocument,
  deleteJudicialTexts,
  saveJudicialDocument,
  saveJudicialText,
  getJudicialTexts,
  uploadFile,
} from "../../../utils/api";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Collapse, Stack } from "@mui/material";
import { handleFileUpload } from "../../../utils";
import Feedback from "../../../components/Feedback";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";


//Formik validation
const validationSchema = Yup.object().shape({
  documents: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Campo obbligatorio"),
      file: Yup.string().required("Campo obbligatorio"),
    })
  ),
});

const validationSchemaTexts = Yup.object().shape({
  texts: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Campo obbligatorio"),
      content: Yup.string().required("Campo obbligatorio"),
    })
  ),
});

//basic document to be pushed on documents array
const basicDocument = {
  title: "",
  file: "",
};
//basic Text group to be pushed on Texts array
const basicText = {
  title: "",
  content: "",
};

const Input = styled("input")({
  display: "none",
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

//Formik default states
const defaultState = {
  documents: [basicDocument],
};

const defaultStateTexts = {
  texts: [basicText],
};

export default function DocumentAreaDetail() {
  const { id } = useParams();
  const [tabValue, setTabValue] = useState("documents");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalText, setOpenModalText] = useState(false);
  const [deletingDocument, setDeletingDocument] = useState(null);
  const [deletingText, setDeletingText] = useState(null);

  //formik for documents
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: defaultState,
    validationSchema: validationSchema,
  });

  //formik for texts
  const formikTexts = useFormik({
    enableReinitialize: true,
    initialValues: defaultStateTexts,
    validationSchema: validationSchemaTexts,
  });

  //funzione che genera una nuova row per il caricamento di un nuovo documento
  function addDocument() {
    const documentRow = [...formik.values.documents];
    documentRow.push(basicDocument); //aggiungo un nuovo campo per il documento
    formik.setFieldValue("documents", documentRow);
  }

    //funzione che genera una nuova row per il caricamento di un nuovo gruppo testuale
    function addText() {
      const textRow = [...formikTexts.values.texts];
      textRow.push(basicText); //aggiungo un nuovo campo per il gruppo testuale
      formikTexts.setFieldValue("texts", textRow);
    }

  //Delete modal functions
  function openDeleteModal(id) {
    setOpenModal(true);
    setDeletingDocument(id);
  }

  function openDeleteModalText(id) {
    setOpenModalText(true);
    setDeletingText(id);
  }

  function closeDeleteModal() {
    setOpenModal(false);
    setDeletingDocument(null);
  }

  function closeDeleteModalText() {
    setOpenModalText(false);
    setDeletingText(null);
  }


  //funzione che cancella il documento
  async function deleteDocument(index) {
    //cancello il documento
    const resDocument = await deleteJudicialDocument(index);
    if (resDocument) {
      //chiudo popup
      setFeedbackMessage("Documento eliminato");
      setShowSnackBar(true);
    } else {
      setFeedbackMessage("Qualcosa è andato storto");
      setShowSnackBar(true);
    }
    setOpenModal(false)
    setDeletingDocument(null);
    getData();
  }

  //funzione che cancella il gruppo testuale
  async function deleteText(index) {
    //cancello il documento
    const resText = await deleteJudicialTexts(index);
    if (resText) {
      //chiudo popup
      setFeedbackMessage("Gruppo testuale eliminato");
      setShowSnackBar(true);
    } else {
      setFeedbackMessage("Qualcosa è andato storto");
      setShowSnackBar(true);
    }
    setOpenModalText(false)
    setDeletingText(null);
    getTexts();
  }
  //save document
  async function saveDocument(document) {
    const resDocument = await saveJudicialDocument(document);
    if (resDocument) {
      setFeedbackMessage("Documento Salvato");
      setShowSnackBar(true);
    } else {
      setFeedbackMessage("Qualcosa è andato storto");
      setShowSnackBar(true);
    }
    getData();
  }

  //save text
  async function saveText(text) {
    const resText = await saveJudicialText(text);
    if (resText) {
      console.log('here ok')
      setFeedbackMessage("Gruppo testuale salvato");
      setShowSnackBar(true);
    } else {
      console.log('here not ok')
      setFeedbackMessage("Qualcosa è andato storto");
      setShowSnackBar(true);
    }
    getTexts();
  }



  //Upload document
  const fileChange = async (e, docIndex) => {
    handleFileUpload(e, (res) => {
      formik.setFieldValue(
        `documents[${docIndex}].file`,
        res
      );
    });
  };

  // const addFile = (rowIndex, documentIndex, fileIndex) => {
  //   const files = [
  //     ...formik.values.rows[rowIndex].documents[documentIndex].file,
  //   ];
  //   files.splice(fileIndex + 1, 0, basicFile);
  //   formik.setFieldValue(
  //     `rows[${rowIndex}].documents[${documentIndex}].file`,
  //     files
  //   );
  // };

  // const deleteFile = (rowIndex, documentIndex, fileIndex) => {
  //   const files = [
  //     ...formik.values.rows[rowIndex].documents[documentIndex].file,
  //   ];
  //   files.splice(fileIndex, 1);
  //   formik.setFieldValue(
  //     `rows[${rowIndex}].documents[${documentIndex}].file`,
  //     files
  //   );
  // };

  // const saveDocument = async (values) => {
  //   const reworkedResult = {
  //     ...values,
  //     rows: values.rows.map((row) => {
  //       return {
  //         ...row,
  //         documents: row.documents.map((document) => {
  //           return {
  //             ...document,
  //             file: document.file.reduce((previousValue, currentValue) => {
  //               previousValue[currentValue.language] = { ...currentValue };
  //               return previousValue;
  //             }, {}),
  //           };
  //         }),
  //       };
  //     }),
  //   };

  

  //all'avvio della pagina, devo settare in formik, all'interno di documents [], tutti i documenti presenti {}

  async function getData() {
    const res = await getJudicialDocuments();
    const judicialDocuments = {
      documents: res,
    };
   
    formik.setValues(judicialDocuments);
   
  }

  async function getTexts(){
    const resTexts = await getJudicialTexts();
    const judicialTexts = {
      texts: resTexts,
    };
    formikTexts.setValues(judicialTexts);
  }


  useEffect(async () => {
    getData();
    getTexts();
  }, []);

  //handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
    <TabContext value={tabValue}>
    <Box sx={{ width:"100%", borderBottom: 1, borderColor: 'divider' }}>
      <TabList onChange={handleTabChange} aria-label="Judicial Documents tab">
        <Tab label="Documenti" value="documents" />
        <Tab label="Testi" value="texts" />
      </TabList>
    </Box>
    <TabPanel value="documents">
      <Stack spacing={4}>
        <h1>AREA DOCUMENTALE - DOCUMENTI</h1>
        <p className="instructions">Cliccare sull'icona "+" accanto alla voce "Aggiungi un nuovo documento" per inserire un nuovo documento. Per ciascun documento è obbligatorio indicare un titolo e caricare un file. Dopo aver inserito titolo e file cliccare sul bottone "salva" della sezione corrispondente.</p>
        {/* DOCUMENT LOOP */}
        {formik.values.documents.map((row, index) => (
          <Stack spacing={2} key={index}>
            <h4 className="judicial-case__title">Documento nr.{index + 1}</h4>

            {/* Titolo del documento */}
            <Item elevation={1}>
              <TextField
                fullWidth
                name={`documents[${index}].title`}
                required
                label={"Titolo Documento"}
                value={row.title}
                onChange={formik.handleChange}

              />
              {/* File */}
              <div className="title__input">
                <span className="file-margin">File*:</span>
                <Chip label={row.file} />
                {row.file !== "" ? (
                 <label htmlFor={`contained-button-file${index}`}>
                 <Input
                   accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                   id={`contained-button-file${index}`}
                   type="file"
                   onChange={
                     (event) => fileChange(event, index) //docIndex fileIndex
                   }
                 />
                 <Button variant="contained" component="span">
                   Modifica Documento
                 </Button>
               </label>
                ) : (
                  <div>
                    {/* chiamare /api/upload */}
                    <label htmlFor={`contained-button-file${index}`}>
                      <Input
                        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                        id={`contained-button-file${index}`}
                        type="file"
                        onChange={
                          (event) => fileChange(event, index) //docIndex fileIndex
                        }
                      />
                      <Button variant="contained" component="span">
                        Upload Documento
                      </Button>
                    </label>
                  </div>
                )}
              </div>
            </Item>

            {/* Delete and Save document button */}
            <Grid container>
              <Grid item xs={12}>
                <div className="buttons">
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<SaveIcon />}
                    onClick={() => saveDocument(row)}
                  >
                    Salva
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<DeleteIcon />}
                    onClick={() => openDeleteModal(row.id)}
                  >
                    Cancella
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Divider />
          </Stack>
        ))}
      </Stack>

      {/* ADD NEW DOCUMENT */}
      <div className="add__container">
        Aggiungi un nuovo documento
        <Fab
          color="primary"
          aria-label="add new document"
          onClick={addDocument}
          size="small"
        >
          <AddIcon />
        </Fab>
      </div>

      {openModal && (
        <div className="overlay">
          <div className="delete__modal">
            <CloseIcon onClick={closeDeleteModal} />
            Confermi l'eliminazione di questo documento?
            <div className="button__flex">
              <Button
                variant="contained"
                onClick={() => deleteDocument(deletingDocument)}
              >
                Elimina
              </Button>
              <Button variant="outlined" onClick={closeDeleteModal}>
                Torna indietro
              </Button>
            </div>
          </div>
        </div>
      )}
    </TabPanel>

      {/* AREA DOCUMENTALE TEXTS */}
      <TabPanel value="texts">
      <Stack>
        <h1 className="judicial__texts-title">AREA DOCUMENTALE - TESTI</h1>
        <p className="instructions">Cliccare sull'icona "+" accanto alla voce "Aggiungi nuovo gruppo di testi" per inserire un nuovo gruppo testuale. Per ciascun gruppo è obbligatorio indicare un titolo e un testo. Dopo aver inserito titolo e testo cliccare sul bottone "salva" della sezione corrispondente.</p>

        <div className="judicial__texts-container">
        {formikTexts.values.texts.map((text, index) => {
          return (
            
            <Stack spacing={2} key={index}>
            <Item elevation={1} >
              <div className="judicial__texts-container">
                <TextField
                  fullWidth
                  name={`texts[${index}].title`}
                  required
                  label={"Titolo"}
                  value={text.title}
                  onChange={formikTexts.handleChange}
                />
                <TextField
                  fullWidth
                  name={`texts[${index}].content`}
                  required
                  multiline
                  label={"Testo"}
                  value={text.content}
                  onChange={formikTexts.handleChange}
                />
              </div>
            </Item>
            {/* save and delete buttons */}
            <Grid container>
              <Grid item xs={12}>
                <div className="buttons">
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<SaveIcon />}
                    onClick={() => saveText(text)}
                  >
                    Salva
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<DeleteIcon />}
                    onClick={() => openDeleteModalText(text.id)}
                  >
                    Cancella
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Divider />
            </Stack>           
          );
        })}
        </div>
      </Stack>
      {/* ADD NEW TEXT GROUP */}
      <div className="add__container">
        Aggiungi nuovo gruppo di testi
        <Fab color="primary" aria-label="add new document" size="small" onClick={addText}>
          <AddIcon />
        </Fab>
      </div>
      {/* delete text modal */}
      {openModalText && (
        <div className="overlay">
          <div className="delete__modal">
            <CloseIcon onClick={closeDeleteModalText} />
            Confermi l'eliminazione di questo gruppi testuale?
            <div className="button__flex">
              <Button
                variant="contained"
                onClick={() => deleteText(deletingText)}
              >
                Elimina
              </Button>
              <Button variant="outlined" onClick={closeDeleteModalText}>
                Torna indietro
              </Button>
            </div>
          </div>
        </div>
      )}
      </TabPanel>
       </TabContext>

      {/* toast message */}
      <Feedback
        open={showSnackBar}
        handleClose={() => setShowSnackBar(false)}
        message={feedbackMessage}
      />
    </>
   
  );
}
