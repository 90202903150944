import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { experimentalStyled as styled } from '@mui/material/styles';
import {useFormik} from 'formik'
import * as Yup from "yup";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { getMembershipDetail, setCompanyDetails } from '../../../utils/membershipsApi';
import Feedback from '../../../components/Feedback';
import { getCountries } from '../../../utils/api';
import { Box, Chip, FormControl, InputLabel, MenuItem, Select, Stack, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { format } from 'date-fns';

const validationSchema = Yup.object().shape({
});
/*
const Input = styled('input')({
  display: 'none',
});
*/

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const defaultState = {
  membership: {
    "date_start": new Date(),
    "date_end":  new Date(),
    "trial_end":  new Date(),
  },
  company: {},
}

export default function MembershipDetail() {
    const [countries, setCountries] = useState([])
    const navigate = useNavigate()
    const [showSnackBar, setShowSnackBar] = useState(false)
    const [feedbackMessage, setFeedbackMessage] = useState("")
    const { id } = useParams();
    const [tabValue, setTabValue] = React.useState('membership');

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: defaultState,
      validationSchema: validationSchema,
      onSubmit:(values) => { 
        save(values)
      }
    });

    const getFormikValues = (type, field) => formik.values[type] ? formik.values[type][field] : false
    const getFormikErrors = (type, field) => formik.errors[type] && formik.errors[type][field]
    
    const save = async (values) => {
      const companyDetail = {...values.company}
      
      const resCompany = await setCompanyDetails(companyDetail)
      if (resCompany) {
        setFeedbackMessage("Azienda Salvata")
        setShowSnackBar(true)
      } else {
        setFeedbackMessage("Qualcosa è andato storto")
        setShowSnackBar(true)
      }
    }

    useEffect(() => {
      async function fetchData() {
        const resCountries = await getCountries()
        if (resCountries) {
          setCountries(resCountries)
        }
        
        if (id) {
          const res = await getMembershipDetail(id)
          if (res) {
            formik.setValues({...res, company: res.membership.company})
          }
        }
      }

      fetchData(id)
    }, []);


  return <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>

      <Box sx={{ width: '100%' }}>
      <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="Membership" value="membership" />
              <Tab label="Utenti" value="users" />
              <Tab label="Dettagli Azienda" value="company" />
              <Tab label="Informazioni di pagamento" value="payments" />
            </TabList>
          </Box>
          <TabPanel value="membership">
            <Stack spacing={4}>
              <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Item>
                        <h2>{formik.values.membership.company && formik.values.membership.company.name}</h2>
                      </Item>
                      <Item>
                        Subscription ID: <Chip label={getFormikValues('membership', 'subscription_id')} />
                      </Item>
                      <Item>
                        Membership: <Chip label={getFormikValues('membership', 'membership') ? 'Attiva' : 'Non Attiva'} />
                      </Item>
                      <Item>
                        Inizio: <Chip label={getFormikValues('membership', 'date_start') != null ? format(new Date(getFormikValues('membership', 'date_start')), 'dd/MM/yyyy'): ""} />
                      </Item>
                      <Item>
                        Scadenza: <Chip label={getFormikValues('membership', 'date_end') != null ? format(new Date(getFormikValues('membership', 'date_end')), 'dd/MM/yyyy'): ""} />
                      </Item>
                      <Item>
                        Scadenza Trial: <Chip label={getFormikValues('membership', 'trial_end') != null ? format(new Date(getFormikValues('membership', 'trial_end')), 'dd/MM/yyyy'): ""} />
                      </Item>
                      <Item>
                        Stato: <Chip label={getFormikValues('membership', 'status')} />
                      </Item>
                      <Item>
                      {getFormikValues('membership', 'customer_url') && <Button variant='contained' type="button" onClick={() => window.location.href = getFormikValues('membership', 'customer_url')}>Vai al Dettaglio Cliente su Stripe</Button>}
                      {getFormikValues('membership', 'subscription_url') && <Button variant='contained' type="button" onClick={() => window.location.href = getFormikValues('membership', 'subscription_url')}>Vai al Dettaglio Membership su Stripe</Button>}
                      </Item>
                    </Grid>
                  </Grid>
              </div>
            </Stack>
          </TabPanel>
          <TabPanel value="users">
            <Stack spacing={4}>
              <div>
                  <Grid container spacing={2}>
                    {formik.values.users && formik.values.users.map(user =><Grid item xs={12}>
                      <Item>
                        <h2>{user.name} {user.surname}</h2>
                        <h3>{user.email}</h3>
                        {user.company_founder === 1 && <div><Chip label={'Founder'} /></div>}
                        <Button variant='contained' type="button" onClick={() => navigate(`/users/${user.id}`)}>Vai al Dettaglio Utente</Button>
                      </Item>
                    </Grid>)}
                  </Grid>
              </div>
            </Stack>
          </TabPanel>
          <TabPanel value="company">
            <Stack spacing={4}>
              <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>                     
                      <Item>
                          <TextField
                            name="company.name"
                            fullWidth
                            id="company_name"
                            label={"Nome"}
                            value={getFormikValues('company', 'name')}
                            onChange={formik.handleChange}
                            error={getFormikErrors('company', 'name')}
                            helperText={getFormikErrors('company', 'name')}
                          />
                      </Item>
                      <Item>
                          <TextField
                            name="company.type"
                            fullWidth
                            id="company_type"
                            label={"Tipo"}
                            value={getFormikValues('company', 'type')}
                            onChange={formik.handleChange}
                            error={getFormikErrors('company', 'type')}
                            helperText={getFormikErrors('company', 'type')}
                          />
                      </Item>
                      <Item>
                          <TextField
                            name="company.address"
                            fullWidth
                            id="company_address"
                            label={"Indirizzo"}
                            value={getFormikValues('company', 'address')}
                            onChange={formik.handleChange}
                            error={getFormikErrors('company', 'address')}
                            helperText={getFormikErrors('company', 'address')}
                          />
                      </Item>
                      <Item>
                          <TextField
                            name="company.city"
                            fullWidth
                            id="company_city"
                            label={"Città"}
                            value={getFormikValues('company', 'city')}
                            onChange={formik.handleChange}
                            error={getFormikErrors('company', 'city')}
                            helperText={getFormikErrors('company', 'city')}
                          />
                      </Item>
                      <Item>
                          <TextField
                            name="company.province"
                            fullWidth
                            id="company_province"
                            label={"Provincia"}
                            value={getFormikValues('company', 'province')}
                            onChange={formik.handleChange}
                            error={getFormikErrors('company', 'province')}
                            helperText={getFormikErrors('company', 'province')}
                          />
                      </Item>
                      <Item>
                          <TextField
                            name="company.postal_code"
                            fullWidth
                            id="company_postal_code"
                            label={"CAP"}
                            value={getFormikValues('company', 'postal_code')}
                            onChange={formik.handleChange}
                            error={getFormikErrors('company', 'postal_code')}
                            helperText={getFormikErrors('company', 'postal_code')}
                          />
                      </Item>
                      <Item>
                        <FormControl fullWidth>
                          <InputLabel id="company_country-label">Paese</InputLabel>
                          <Select
                            labelId="company_country-label"
                            id="company_country"
                            name="company.country"
                            value={getFormikValues('company', 'country')}
                            label="Paese"
                            onChange={formik.handleChange}
                            error={getFormikErrors('company', 'country')}
                            helperText={getFormikErrors('company', 'country')}
                          >
                            {countries.map(country => <MenuItem key={country.name} value={country.name}>{country.name}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </Item>
                      <Item>
                          <TextField
                            name="company.vat"
                            fullWidth
                            id="company_vat"
                            label={"VAT"}
                            value={getFormikValues('company', 'vat')}
                            onChange={formik.handleChange}
                            error={getFormikErrors('company', 'vat')}
                            helperText={getFormikErrors('company', 'vat')}
                          />
                      </Item>
                    </Grid>
                  </Grid>
              </div>
            </Stack>
          </TabPanel>
          <TabPanel value="payments">
            <Stack spacing={4}>
              <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>                     
                      <Item>
                        Stato pagamento: <Chip label={getFormikValues('payment', 'status')} />
                      </Item>
                      {getFormikValues('payment', 'paid_at') && <Item>
                        Data pagamento: <Chip label={format(new Date(getFormikValues('payment', 'paid_at')), 'dd-MM-yyyy')} />
                      </Item>}
                      <Item>
                        Coupon: <Chip label={getFormikValues('payment', 'coupon_code')} />
                      </Item>
                      <Item>
                        Sconto: <Chip label={getFormikValues('payment', 'discount')} />
                      </Item>
                    </Grid>
                  </Grid>
              </div>
            </Stack>
          </TabPanel>
        </TabContext>
      </Box>
      
      <Button variant='contained' type="submit" onClick={formik.handleSubmit}>Save</Button>
      
    </LocalizationProvider>
    <Feedback
      open={showSnackBar}
      handleClose={() => setShowSnackBar(false)}
      message={feedbackMessage}
    />
  </>
}
