import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { experimentalStyled as styled } from '@mui/material/styles';
import {useFormik} from 'formik'
import * as Yup from "yup";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { getIdarb, updateIdarb } from '../../../utils/idarb';
import Feedback from '../../../components/Feedback';
import { getCountries } from '../../../utils/api';
import { Chip, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { handleFileUpload } from '../../../utils';

const validationSchema = Yup.object().shape({
});

const Input = styled('input')({
  display: 'none',
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
  
}));

const FlexDiv = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'row',
  gap: '20px'
  
}));

const defaultState = {
  approved: 0,
  arbitration_experience: "",
  country: "",
  curriculum: "",
  distribution_cases: [],
  email: "",
  experience_field_distribution: "",
  experience_in_house: "",
  first_reccomandation: "",
  group: "",
  id: 0,
  image: null,
  languages: "",
  litigation_experience: "",
  name: "",
  phone: "",
  professional_qualification: "",
  second_reccomandation: "",
  surname: "",
  updated_at: null,
  user_id: 0,
  year_of_birth: 0
}

export default function IdarbDetail() {
    const [countries, setCountries] = useState([])
   
    const [showSnackBar, setShowSnackBar] = useState(false)
    const [feedbackMessage, setFeedbackMessage] = useState("")
    const { id } = useParams();

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: defaultState,
      validationSchema: validationSchema,
      onSubmit:(values) => { 
        save(values)
      }
    });


    const save = async (values) => {
      const idarbDetail = {...values}
      const resIdarb = await updateIdarb(idarbDetail)
      if (resIdarb) {
        setFeedbackMessage("Arbitrator Salvato")
        setShowSnackBar(true)
      } else {
        setFeedbackMessage("Qualcosa è andato storto")
        setShowSnackBar(true)
      }
    }

    const fileChange = async (e, field) => {
      handleFileUpload(e, (res) => formik.setFieldValue(field, res))
    }


    useEffect(() => {
      async function fetchData() {
        const resCountries = await getCountries()
        if (resCountries) {
          setCountries(resCountries)
        }
        
        if (id) {
          const res = await getIdarb(id)
          if (res) {
            formik.setValues(res)
          }
        }
      }

      fetchData(id)
    }, []);

  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={4}>
      <div>
        <h3 className='idarb__email'>{formik.values.email}</h3>
          <Grid container spacing={20}>
            <Grid item xs={12}>
              <Stack spacing={2} marginBottom={4}>
                {/* image */}
                <Item>
                  <img
                    src={
                      formik.values.image &&
                      `${process.env.REACT_APP_API_HOST}/api/image/${formik.values.image}`
                    }
                    alt=""
                    width="100"
                  />
                  <label htmlFor={`contained-button-image`}>
                    <Input
                      accept="image/*"
                      id={`contained-button-image`}
                      type="file"
                      onChange={(event) => fileChange(event, "image")}
                    />
                    <Button variant="contained" component="span">
                      Upload Immagine
                    </Button>
                  </label>
                </Item>
                  <Item>
                    <FormControl fullWidth>
                      <p id="country-label">Name</p>
                      <h3>{formik.values.name} {formik.values.surname}</h3>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="group-label">Group</InputLabel>
                      <Select
                        labelId="group-label"
                        id="group"
                        name="group"
                        value={formik.values.group}
                        label="Group"
                        onChange={formik.handleChange}
                        error={formik.errors.group}
                        helperText={formik.errors.group}
                      >
                        <MenuItem key={"Chair/sole arbitrators"} value={"Chair/sole arbitrators"}>{"Chair/sole arbitrators"}</MenuItem>
                        <MenuItem key={"Party appointed arbitrators"} value={"Party appointed arbitrators"}>{"Party appointed arbitrators"}</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                        name="languages"
                        fullWidth
                        id="languages"
                        label={"Languages"}
                        value={formik.values.languages}
                        onChange={formik.handleChange}
                        error={formik.errors.languages}
                        helperText={formik.errors.languages}
                      />
                    <FormControl fullWidth>
                      <InputLabel id="country-label">Nationality</InputLabel>
                      <Select
                        labelId="country-label"
                        id="country"
                        name="country"
                        value={formik.values.country}
                        label="Country"
                        onChange={formik.handleChange}
                        error={formik.errors.country}
                        helperText={formik.errors.country}
                      >
                        {countries.map(country => <MenuItem key={country.name} value={country.name}>{country.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <TextField
                        name="year_of_birth"
                        fullWidth
                        id="year_of_birth"
                        label={"Year of Birth"}
                        value={formik.values.year_of_birth}
                        onChange={formik.handleChange}
                        error={formik.errors.year_of_birth}
                        helperText={formik.errors.year_of_birth}
                      />
                  </Item>
              </Stack>
              <Stack spacing={2} marginBottom={4}>
                <Item>
                    <TextField
                      name="professional_qualification"
                      fullWidth
                      id="professional_qualification"
                      label={"Professional Qualifications"}
                      value={formik.values.professional_qualification}
                      onChange={formik.handleChange}
                      error={formik.errors.professional_qualification}
                      helperText={formik.errors.professional_qualification}
                    />
                    <TextField
                      name="experience_field_distribution"
                      fullWidth
                      id="experience_field_distribution"
                      label={"Experience in the field of distribution"}
                      value={formik.values.experience_field_distribution}
                      onChange={formik.handleChange}
                      error={formik.errors.experience_field_distribution}
                      helperText={formik.errors.experience_field_distribution}
                    />

                    <TextField
                      name="litigation_experience"
                      fullWidth
                      id="litigation_experience"
                      label={"Litigation"}
                      value={formik.values.litigation_experience}
                      onChange={formik.handleChange}
                      error={formik.errors.litigation_experience}
                      helperText={formik.errors.litigation_experience}
                    />

                    <TextField
                      name="arbitration_experience"
                      fullWidth
                      id="arbitration_experience"
                      label={"Arbitration experience"}
                      value={formik.values.arbitration_experience}
                      onChange={formik.handleChange}
                      error={formik.errors.arbitration_experience}
                      helperText={formik.errors.arbitration_experience}
                    />

                    <TextField
                      name="experience_in_house"
                      fullWidth
                      id="experience_in_house"
                      label={"Experience as in house counsel"}
                      value={formik.values.experience_in_house}
                      onChange={formik.handleChange}
                      error={formik.errors.experience_in_house}
                      helperText={formik.errors.experience_in_house}
                    />
                </Item>
              </Stack>
              <Stack spacing={2} marginBottom={4}>
              {formik.values.distribution_cases && formik.values.distribution_cases.map((caseItem,index) => 
                <Item>
                  <TextField
                    name={`distribution_cases[${index}].title`}
                    fullWidth
                    id={`distribution_cases[${index}].title`}
                    label={`Case ${index+1} - Title`}
                    value={caseItem.title}
                    onChange={formik.handleChange}
                    helperText={formik.errors.distribution_cases?.[index]?.title && formik.errors.distribution_cases?.[index]?.title}
                    error={formik.errors.distribution_cases?.[index]?.title ? true : false}
                  />
                  <TextField
                    name={`distribution_cases[${index}].description`}
                    fullWidth
                    id={`distribution_cases[${index}].description`}
                    label={`Case ${index+1} - Description`}
                    value={caseItem.description}
                    onChange={formik.handleChange}
                    helperText={formik.errors.distribution_cases?.[index]?.description && formik.errors.distribution_cases?.[index]?.description}
                    error={formik.errors.distribution_cases?.[index]?.description ? true : false}
                  />
                  </Item>
                )}
              </Stack>
              <Stack spacing={2} marginBottom={4}>
                <Item>
                  <p>Detailed profile/CV</p>
                  <FlexDiv>
                    <Chip label={formik.values.curriculum}/>
                    <label htmlFor={`contained-button-curriculum`}>
                      <Input accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf" id={`contained-button-curriculum`} type="file"  onChange={(event) => fileChange(event, 'curriculum')}/>
                      <Button variant="contained" component="span">
                        Upload Curriculum
                      </Button>
                    </label>
                  </FlexDiv>
                </Item>
                <Item>
                  <p>First reccomandation</p>
                  <FlexDiv>
                    <Chip label={formik.values.first_reccomandation}/>
                    <label htmlFor={`contained-button-first_reccomandation`}>
                      <Input accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf" id={`contained-button-first_reccomandation`} type="file"  onChange={(event) => fileChange(event, 'first_reccomandation')}/>
                      <Button variant="contained" component="span">
                        Upload File
                      </Button>
                    </label>
                  </FlexDiv>
                </Item>
                <Item>
                  <p>Second reccomandation</p>
                  <FlexDiv>
                    <Chip label={formik.values.second_reccomandation}/>
                    <label htmlFor={`contained-button-second_reccomandation`}>
                      <Input accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf" id={`contained-button-second_reccomandation`} type="file"  onChange={(event) => fileChange(event, 'second_reccomandation')}/>
                      <Button variant="contained" component="span">
                        Upload File
                      </Button>
                    </label>
                  </FlexDiv>
                </Item>
              </Stack>
            </Grid>
          </Grid>
      </div>
      <Button variant='contained' type="submit" onClick={formik.handleSubmit}>Save</Button>
    </Stack>
  </LocalizationProvider>
  <Feedback
    open={showSnackBar}
    handleClose={() => setShowSnackBar(false)}
    message={feedbackMessage}
  />
  </>
}
