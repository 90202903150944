import axios from "axios"

export async function getExperts() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/experts`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.experts
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}


export async function getExpertDetail(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/experts/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.expert
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function setExpertDetail(expert) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/experts${expert.id ? `/${expert.id}` : ''}`, {
            method: expert.id ? "PUT" : "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            data: {expert}
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function deleteExpert(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/experts/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}