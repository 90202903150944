import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { checkAuth, doAuth } from "../utils/api";
import { useUser } from "./UserContext";

export const LoginContext = React.createContext();

export const LoginProvider = ({ children }) => {
  const {saveUser} = useUser()
  const navigate = useNavigate()

  const doLogin = async ({email, password}) => {
    try {
      const res = await doAuth(email, password)
      if (res) {
        updateUser(res)
        return true
      }
      navigate('/')
    } catch(e) {
      navigate('/')
      resetUser()
      return false
    }
  }

  const updateUser = (values) => {
    if (values.auth_token) localStorage.setItem('token', values.auth_token)
    saveUser(values)
  }

  const doLogout = () => {
    localStorage.removeItem('token')
    navigate('/')
    resetUser({})
  }

  const resetUser = () => {
    saveUser({})
  }

  useEffect(() => {
    const checkLogin = async () => {
      try {
        const res = await checkAuth(localStorage.getItem('token'))
        if (res) {
          updateUser(res)
          return true
        }
        
        navigate('/')
        
      } catch(e) {
        navigate('/')
        resetUser()
        return false
      }
    }

    if (localStorage.getItem('token')) checkLogin()
    else navigate('/')
  }, []);
  

  return (
    <LoginContext.Provider value={{ doLogin, doLogout }}>
      {children}
    </LoginContext.Provider>
  );
};

export const LoginConsumer = LoginContext.Consumer;

export function useLogin() {
    const context = useContext(LoginContext);
    return context;
};