import axios from "axios"

export async function getUsers() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/users`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.users
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getUserDetail(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/users/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.user
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

//OLD
// export async function setUserDetail(user) {
//     try {
//         const token = localStorage.getItem('token')
//         const res = await axios(`${process.env.REACT_APP_API_HOST}/api/users${user.id ? `/${user.id}` : ''}`, {
//             method: user.id ? "PUT" : "POST",
//             headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
//             data: {user, billing_information: {...user.billing_information}}
//         })
//         if (res.data.status === "success") {
//             return true
//         }
//         return false
//     } catch (e) {
//         console.log(e)
//         return false
//     }
// }

export async function setUserDetail(user) {
    try {
      const token = localStorage.getItem('token');
      const apiUrl = `${process.env.REACT_APP_API_HOST}/api/users${user.id ? `/${user.id}` : ''}`;
      
      let data = { user };
      console.log('user billing info',user.billing_information === null)
      if (user.billing_information !== null && Object.keys(user.billing_information).length > 0) {
        data.billing_information = { ...user.billing_information };
      }
      
      const res = await axios(apiUrl, {
        method: user.id ? "PUT" : "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        data
      });

      
  
      if (res.data.status === "success") {
        return true;
      }
      
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }



export async function deleteUser(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/users/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}
