import axios from "axios"

export async function getMemberships() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/memberships`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.memberships
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getMembershipDetail(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/memberships/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function setCompanyDetails(company) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/company/${company.id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            data: {company}
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }  
}
