import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import { exportOrders, getOrders } from '../../../utils/ordersApi';
import Button from '@mui/material/Button';
import Title from '../../../components/Title';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { format } from 'date-fns';

export default function OrdersList() {
    const [orders, setOrders] = useState([])
    const { id } = useParams();

    const navigate = useNavigate()

    const doExportOrders = async () => {
      if (id) {
        await exportOrders(id)
      }
    }
    
    const getFirstParticipant = (partecipants) => {
      const owner = partecipants[0]
      if (owner) return owner.name
      return "";
    }

    /*
    const getCompanyOwner = (partecipants) => {
      const owner = partecipants.find(partecipant => partecipant.company_founder === 1)
      if (owner) return owner.name
      return "";
    }
    */

    useEffect(() => {
      const fetchData = async () => {
        if (id) {
          const res = await getOrders(id)
          if (res) {
            setOrders(res.data)
          } 
        }
      }

      fetchData();
    }, []);

    return (
        <React.Fragment>
          <Title>Ordini</Title>
          <Button variant='contained' type="submit" onClick={doExportOrders}>Export</Button>
          <DataGrid
            autoHeight
            columns={[
              { field: 'created_at', type: "date", headerName:'Data', renderCell: (cellValues) => { return cellValues.row.created_at && format(new Date(cellValues.row.created_at), 'dd/MM/yyyy') }  },
              { field: 'company.name', headerName:'Azienda', width: 300, 
                valueGetter: (cellValues) => { return cellValues.row.company && cellValues.row.company.name }
              },
              { field: 'company_owner', headerName:'Participant', width: 200, valueGetter: (cellValues) => { return getFirstParticipant(cellValues.row.partecipants) } },
              { field: 'price', headerName:'Fee', width: 100, renderCell: (cellValues) => { return cellValues.row.price && `${cellValues.row.price / 100} €` }},
              { field: 'paid_at', type: "date", headerName:'Data Pagamento', renderCell: (cellValues) => { return cellValues.row.paid_at && format(new Date(cellValues.row.paid_at), 'dd/MM/yyyy') }  },
              { field: 'status', headerName:'Status', width: 120, 
                type: 'singleSelect',
                valueOptions: ['Completed', 'Not Completed'],
                valueGetter: (cellValues) => { return cellValues.row.paid_at ? 'Completed' : 'Not Completed' }
              },
              { field: '', headerName:'Dettaglio', width: 120, 
                filterable: false,
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <Button variant="contained" onClick={() => navigate(`/order/${row.order_id}`)}>
                    Dettaglio
                  </Button>
                } 
              },
            ]}
            getRowId={(row) => row.order_id}
            rows={orders}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </React.Fragment>
      );
}
