import { uploadFile } from "./api";

export const handleFileUpload = async (e, callback) => {
    if (e.target.files !== undefined) {
        const fileSize = e.target.files[0].size / 1024 / 1024;
        if (fileSize > 2) return alert('File size exceeds 2 MiB');

        var reader = new FileReader();
        reader.onload = async function () {
            const res = await uploadFile(e.target.files[0])
            callback(res)
        };
        reader.readAsDataURL(e.target.files[0]);
    }
}