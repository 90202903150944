import React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import {useFormik} from 'formik'
import * as Yup from "yup";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { Stack } from '@mui/material';
import { exportCsv } from '../../utils/api';

const validationSchema = Yup.object().shape({
  date_start: Yup.date().required("Campo obbligatorio"),
  date_end: Yup.date().required("Campo obbligatorio").when("date_start", (date_start, yup) => date_start && yup.min(date_start, "La data finale non può essere precedente a quella di inizio")),
});
/*
const Input = styled('input')({
  display: 'none',
});
*/

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const defaultState = {
  date_start: "",
  date_end: "",
}

export default function Payments() {

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: defaultState,
      validationSchema: validationSchema,
      onSubmit:(values) => { 
        save(values)
      }
    });

    const save = async (values) => {
      exportCsv(values)
    }

  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={4}>
      <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item>
                  <DesktopDatePicker
                    label="Starting Date"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.date_start}
                    onChange={(value) => formik.setFieldValue('date_start', value)}
                    renderInput={(params) => <TextField {...params} error={formik.errors.date_start} helperText={formik.errors.date_start} />}
                    error={formik.errors.date_start}
                  />
              </Item>
              <Item>
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.date_end}
                    onChange={(value) => formik.setFieldValue('date_end', value)}
                    renderInput={(params) => <TextField {...params} error={formik.errors.date_end} helperText={formik.errors.date_end} />}
                    error={formik.errors.date_end}
                  />
              </Item>
            </Grid>
          </Grid>
      </div>
      <Button variant='contained' type="submit" onClick={formik.handleSubmit}>Export</Button>
    </Stack>
  </LocalizationProvider>
  </>
}
