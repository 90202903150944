import React, { useEffect, useState } from 'react';
import { confirmIdarb, deleteIdarb, getIdarbList } from '../../../utils/idarb';
import Title from '../../../components/Title';
import { IconButton } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';

export default function IdarbList() {
    const [idarbList, setIdarbList] = useState([])
    const [itemToDelete, setItemToDelete] = useState(null)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const navigate = useNavigate()

    const confirm = async (id) => {
      await confirmIdarb(id)
      await fetchData()
    }

    const fetchData = async () => {
      const res = await getIdarbList()
        if (res) setIdarbList(res)
    }

    const askToDelete = (id) => {
      setShowDeleteConfirmation(true)
      setItemToDelete(id)
    }

    const confirmDelete = async () => {
      const res = await deleteIdarb(itemToDelete)
      if (res) fetchData()
      resetDelete()
    }

    const resetDelete = () => {
      setShowDeleteConfirmation(false)
      setItemToDelete(null)
    }

    useEffect(() => {
      fetchData()
    }, []);

    return (
        <React.Fragment>
          <Title>Lista Arbitrator</Title>

          <DataGrid
            autoHeight
            columns={[
              { field: 'id', headerName:'Identificativo Arbitrator', width: 100 },
              { field: 'email', headerName:'Email', width: 750 },
              { field: 'confirm', headerName:'Stato', width: 100, 
                type: 'singleSelect',
                valueOptions: ['Confermato', 'Non confermato'],
                valueGetter: (cellValues) => { return cellValues.row.approved ? 'Confermato' : 'Non confermato' }
              },
              { field: 'confirm', headerName:'', width: 50, filterable: false,
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return row.approved ? <CheckIcon /> : 
                  <IconButton variant="contained" onClick={() => confirm(row.id)}>
                    <AccessTimeIcon />
                  </IconButton>
                } 
              },
              { field: 'detail', headerName:'', width: 50, 
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <IconButton variant="contained" onClick={() => navigate(`/arbitrators/${row.id}`)}>
                    <EditIcon />
                  </IconButton>
                } 
              },
              { field: 'delete', headerName:'', width: 50, 
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <IconButton variant="contained" onClick={() => askToDelete(row.id)}>
                    <DeleteIcon />
                  </IconButton>
                } 
              }
            ]}
            getRowId={(row) => row.id}
            rows={idarbList}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellClick={(e) => console.log(e)}
          />
          <ConfirmationDialog title={"Confermi la cancellazione?"} content={""} open={showDeleteConfirmation} handleClose={resetDelete} handleConfirm={confirmDelete} />
        </React.Fragment>
      );
}
