import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getCategories,
  getDocumentDetail,
  getExperts,
  getLanguages,
  saveDocumentDetail,
} from "../../../utils/api";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { Collapse, Stack } from "@mui/material";
import { handleFileUpload } from "../../../utils";
import Feedback from "../../../components/Feedback";

const validationSchema = Yup.object().shape({
  category_id: Yup.number().required("Required"),
  title: Yup.string().nullable(true),
  rows: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().nullable(true),
      content: Yup.string().nullable(true),
      documents: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().nullable(),
          expert_id: Yup.number().nullable(true),
          file: Yup.array().of(
            Yup.object().shape({
              file: Yup.string().nullable(true),
              language: Yup.string(),
            })
          ),
        })
      ),
    })
  ),
});

const Input = styled("input")({
  display: "none",
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const basicFile = {
  language: "en",
  language_name: "English",
  file: null,
};

const basicDocument = {
  title: "",
  expert_id: "",
  file: [],
};

const basicRow = {
  title: "",
  content: "",
  documents: [],
};

const defaultState = {
  category_id: "",
  title: "",
  rows: [basicRow],
};

export default function DocumentDetail() {
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [experts, setExperts] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: defaultState,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveDocument(values);
    },
  });

  const addRow = (index) => {
    const rows = [...formik.values.rows];
    rows.splice(index + 1, 0, basicRow);
    formik.setFieldValue("rows", rows);
  };

  const deleteRow = (index) => {
    const rows = [...formik.values.rows];
    rows.splice(index, 1);
    formik.setFieldValue("rows", rows);
  };

  const addDocument = (rowIndex, documentIndex) => {
    const documents = [...formik.values.rows[rowIndex].documents];
    documents.splice(documentIndex + 1, 0, basicDocument);
    formik.setFieldValue(`rows[${rowIndex}].documents`, documents);
  };

  const deleteDocument = (rowIndex, documentIndex) => {
    const documents = [...formik.values.rows[rowIndex].documents];
    documents.splice(documentIndex, 1);
    formik.setFieldValue(`rows[${rowIndex}].documents`, documents);
  };

  const addFile = (rowIndex, documentIndex, fileIndex) => {
    const files = [
      ...formik.values.rows[rowIndex].documents[documentIndex].file,
    ];
    files.splice(fileIndex + 1, 0, basicFile);
    formik.setFieldValue(
      `rows[${rowIndex}].documents[${documentIndex}].file`,
      files
    );
  };

  const deleteFile = (rowIndex, documentIndex, fileIndex) => {
    const files = [
      ...formik.values.rows[rowIndex].documents[documentIndex].file,
    ];
    files.splice(fileIndex, 1);
    formik.setFieldValue(
      `rows[${rowIndex}].documents[${documentIndex}].file`,
      files
    );
  };

  const saveDocument = async (values) => {
    const reworkedResult = {
      ...values,
      rows: values.rows.map((row) => {
        return {
          ...row,
          documents: row.documents.map((document) => {
            return {
              ...document,
              file: document.file.reduce((previousValue, currentValue) => {
                previousValue[currentValue.language] = { ...currentValue };
                return previousValue;
              }, {}),
            };
          }),
        };
      }),
    };

    const resDocument = await saveDocumentDetail(reworkedResult);

    if (resDocument) {
      setFeedbackMessage("Documento Salvato");
      setShowSnackBar(true);
    } else {
      setFeedbackMessage("Qualcosa è andato storto");
      setShowSnackBar(true);
    }
  };

  const fileChange = async (e, rowIndex, documentIndex, fileIndex) => {
    handleFileUpload(e, (res) => {
      formik.setFieldValue(
        `rows[${rowIndex}].documents[${documentIndex}].file[${fileIndex}].file`,
        res
      );
    });
  };

  useEffect(() => {
    async function getData() {
      const categories = await getCategories();
      if (categories) setCategories(categories);
      const resLanguages = await getLanguages();
      if (resLanguages) setLanguages(resLanguages);
      const experts = await getExperts();
      if (experts) setExperts(experts);

      if (id) {
        const res = await getDocumentDetail(id);
        if (res) {
          const reworkedResult = {
            ...res,
            rows: res.rows.map((row) => {
              return {
                ...row,
                documents: row.documents.map((document) => {
                  return {
                    ...document,
                    file: Object.keys(document.file).map((language) => {
                      return {
                        ...document.file[language],
                        language,
                      };
                    }),
                  };
                }),
              };
            }),
          };

          formik.setValues(reworkedResult);
        }
      }
    }

    getData();
  }, []);

  const handleChange = (e) => {
    console.log(formik.errors);
    formik.handleChange(e);
  }

  return (
    <>
      <Stack spacing={4}>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
        <Stack spacing={2}>
          <Grid item xs={12}>
            <Item>
              <InputLabel id="categories-label">Category</InputLabel>
              <Select
                required
                labelId="categories-label"
                id="categories"
                value={formik.values.category_id}
                name={`category_id`}
                onChange={formik.handleChange}
                label="Category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </Item>
            <Item>
              <TextField
                required
                name="title"
                fullWidth
                id="title"
                label={"Titolo"}
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.errors.title}
              />
            </Item>
          </Grid>
        </Stack>

        {formik.values.rows.map((row, index) => (
          <Stack key={index}>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <Fab
                size="small"
                onClick={() =>
                  setExpandedRow(expandedRow === index ? null : index)
                }
              >
                {expandedRow === index ? <ArrowUpward /> : <ArrowDownward />}
              </Fab>
              <h2>{row.title || "Nuovo Capitolo"}</h2>
              <Fab
                color="primary"
                aria-label="add"
                onClick={() => addRow(index)}
              >
                <AddIcon />
              </Fab>
              {formik.values.rows.length > 1 && (
                <Fab
                  color="secondary"
                  aria-label="delete"
                  onClick={() => deleteRow(index)}
                >
                  <DeleteIcon />
                </Fab>
              )}
            </Stack>
            <Collapse in={expandedRow === index}>
              <Stack spacing={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Item elevation={1}>
                      <TextField
                        fullWidth
                        name={`rows[${index}].title`}
                        required
                        label={"Titolo"}
                        value={row.title}
                        onChange={formik.handleChange}
                      />
                    </Item>
                    <Item>
                      <TextField
                        fullWidth
                        name={`rows[${index}].content`}
                        label={"Descrizione"}
                        value={row.content}
                        onChange={formik.handleChange}
                        multiline
                      />
                    </Item>
                    <Divider />
                    {row.documents.length === 0 && (
                      <Stack spacing={2}>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          alignItems="center"
                        >
                          <h3>Documento</h3>
                          <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => addDocument(index, 0)}
                            size="small"
                          >
                            <AddIcon />
                          </Fab>
                        </Stack>
                      </Stack>
                    )}
                    {row.documents.map((document, docIndex) => (
                      <Stack key={docIndex} spacing={2}>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          alignItems="center"
                        >
                          <h3>Documento</h3>
                          <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => addDocument(index, docIndex)}
                            size="small"
                          >
                            <AddIcon />
                          </Fab>
                          <Fab
                            color="secondary"
                            aria-label="delete"
                            onClick={() => deleteDocument(index, docIndex)}
                            size="small"
                          >
                            <DeleteIcon />
                          </Fab>
                        </Stack>

                        <Stack spacing={2}>
                          <Item>
                            <TextField
                              fullWidth
                              required
                              name={`rows[${index}].documents[${docIndex}].title`}
                              label={"Titolo Documento"}
                              value={document.title}
                              error={
                                formik.errors.rows?.[index]?.documents?.[
                                  docIndex
                                ]?.title
                              }
                              multiline
                              onChange={handleChange}
                            />
                          </Item>
                          <Item>
                            <InputLabel id={`expert-label${index}${docIndex}`}>
                              Expert
                            </InputLabel>
                            {experts.length > 0 && (
                              <Select
                                labelId={`expert-label${index}${docIndex}`}
                                id="expert"
                                value={document.expert_id || ""}
                                name={`rows[${index}].documents[${docIndex}].expert_id`}
                                onChange={formik.handleChange}
                                label="Expert"
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {experts.map((item) => (
                                  <MenuItem key={item.name} value={item.id}>
                                    {item.name} {item.surname}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </Item>
                        </Stack>
                        <Item>
                          <Stack spacing={2}>
                            {document.file.length === 0 && (
                              <Stack
                                direction={"row"}
                                spacing={2}
                                alignItems="center"
                              >
                                <h3>Files</h3>
                                <Fab
                                  color="primary"
                                  aria-label="add"
                                  onClick={() => addFile(index, docIndex, 0)}
                                  size="small"
                                >
                                  <AddIcon />
                                </Fab>
                              </Stack>
                            )}

                            {document.file.map((file, fileIndex) => (
                              <Stack
                                key={file.file}
                                direction={"row"}
                                spacing={4}
                                alignItems="center"
                              >
                                <InputLabel
                                  id={`language-label${index}${docIndex}${fileIndex}`}
                                >
                                  Language
                                </InputLabel>
                                {languages.length > 0 && (
                                  <Select
                                    labelId={`language-label${index}${docIndex}${fileIndex}`}
                                    id="language"
                                    value={file.language}
                                    name={`rows[${index}].documents[${docIndex}].file[${fileIndex}].language`}
                                    onChange={formik.handleChange}
                                    label="Language"
                                  >
                                    {languages.map((item) => (
                                      <MenuItem
                                        key={item.code}
                                        value={item.code}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                                <Chip label={file.file} />
                                <label
                                  htmlFor={`contained-button-file${index}${docIndex}${fileIndex}`}
                                >
                                  <Input
                                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                                    id={`contained-button-file${index}${docIndex}${fileIndex}`}
                                    type="file"
                                    onChange={(event) =>
                                      fileChange(
                                        event,
                                        index,
                                        docIndex,
                                        fileIndex
                                      )
                                    }
                                  />
                                  <Button variant="contained" component="span">
                                    Upload Documento
                                  </Button>
                                </label>
                                <div style={{ display: "flex" }}>
                                  <Fab
                                    color="primary"
                                    aria-label="add"
                                    onClick={() =>
                                      addFile(index, docIndex, fileIndex)
                                    }
                                    size="small"
                                  >
                                    <AddIcon />
                                  </Fab>
                                  <Fab
                                    color="secondary"
                                    aria-label="delete"
                                    onClick={() =>
                                      deleteFile(index, docIndex, fileIndex)
                                    }
                                    size="small"
                                  >
                                    <DeleteIcon />
                                  </Fab>
                                </div>
                              </Stack>
                            ))}
                          </Stack>
                        </Item>
                        <Divider />
                      </Stack>
                    ))}
                  </Grid>
                </Grid>
              </Stack>
            </Collapse>
          </Stack>
        ))}
      </Stack>
      <Feedback
        open={showSnackBar}
        handleClose={() => setShowSnackBar(false)}
        message={feedbackMessage}
      />
    </>
  );
}
