import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Title from '../../../components/Title';
import { Button, IconButton } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { deleteExpert, getExperts } from '../../../utils/expertsApi';

export default function ExpertsList() {
    const [experts, setExperts] = useState([])
    const [itemToDelete, setItemToDelete] = useState(null)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const navigate = useNavigate()

    const fetchData = async () => {
      const res = await getExperts()
        if (res){
          const expertsResult = res.sort((a, b) => a.surname.toLowerCase() > b.surname.toLowerCase() ? 1:-1) 
          setExperts(expertsResult)
        } 
    }


    const askToDelete = (id) => {
      setShowDeleteConfirmation(true)
      setItemToDelete(id)
    }

    const confirmDelete = async () => {
      const res = await deleteExpert(itemToDelete)
      if (res) fetchData()
      resetDelete()
    }

    const resetDelete = () => {
      setShowDeleteConfirmation(false)
      setItemToDelete(null)
    }

    useEffect(() => {
      fetchData()
    }, []);

    return (
        <React.Fragment>
          <Title>Esperti</Title>
          <div>
            <Button variant='contained' onClick={() => navigate("/experts/new")} >Aggiungi Esperto</Button>
          </div>
          <DataGrid
            autoHeight
            columns={[
              { field: 'email', headerName:'Email', width: 150 },
              { field: 'surname', headerName:'Cognome', width: 150 },
              { field: 'name', headerName:'Nome', width: 150 },
              { field: 'email', headerName:'Email', width: 250 },
              { field: 'detail', headerName:'', width: 50, 
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <IconButton variant="contained" onClick={() => navigate(`/experts/${row.id}`)}>
                    <EditIcon />
                  </IconButton>
                } 
              },
              { field: 'delete', headerName:'', width: 50, 
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <IconButton variant="contained" onClick={() => askToDelete(row.id)}>
                    <DeleteIcon />
                  </IconButton>
                } 
              }
            ]}
            getRowId={(row) => row.id}
            rows={experts}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellClick={(e) => console.log(e)}
          />
          <ConfirmationDialog title={"Confermi la cancellazione?"} content={""} open={showDeleteConfirmation} handleClose={resetDelete} handleConfirm={confirmDelete} />
        </React.Fragment>
      );
}
