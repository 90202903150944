import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { experimentalStyled as styled } from '@mui/material/styles';
import {useFormik} from 'formik'
import * as Yup from "yup";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getConferenceDetail, setConferenceDetail } from '../../../utils/conferencesApi';
import { getPrices, setPrices } from '../../../utils/pricesApi';
import { format } from 'date-fns';
import Feedback from '../../../components/Feedback';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { handleFileUpload } from '../../../utils';

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Campo obbligatorio"),
  vat_rule: Yup.string().required("Campo obbligatorio"),
  country: Yup.string().required("Campo obbligatorio"),
  description: Yup.string().required("Campo obbligatorio"),
  image: Yup.string().required("Campo obbligatorio"),
  date_start: Yup.date().required("Campo obbligatorio"),
  date_end: Yup.date().required("Campo obbligatorio").when("date_start", (date_start, yup) => date_start && yup.min(date_start, "La data finale non può essere precedente a quella di inizio")),
  price: Yup.number().required(),
  optionals: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required("Campo obbligatorio"),
        price: Yup.string().required("Campo obbligatorio"),
      })
    ),
  workshops: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required("Campo obbligatorio"),
      })
    )
}).test('country-vat_rule', 'Quando il paese è Italia, la regola IVA deve essere "all"', function(value) {
  const { country, vat_rule } = value;
  if (country === 'italy' && vat_rule !== 'all') {
    return this.createError({ path: 'vat_rule', message: 'Quando il paese è Italia, l\'IVA deve essere applicata a tutti gli utenti' });
  } else if (country === 'abroad' && vat_rule === 'all') {
    return this.createError({ path: 'vat_rule', message: 'Quando il paese è Estero, l\'IVA deve essere applicata solo agli utenti italiani' });
  }
  return true;
});

const Input = styled('input')({
  display: 'none',
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const defaultOptional = {
  title: '',
  price: ''
}

const defaultWorkshop = {
  title: ''
}

const defaultState = {
  title: '',
  vat_rule: '',
  country: '',
  description: '',
  date_start: '',
  date_end: '',
  price: '',
  image: '',
  PRICE_CONFERENCE_MEMBER: '',
  PRICE_CONFERENCE_GUESTS: '',
  PRICE_CONFERENCE_EXPERT: '',
  PRICE_CONFERENCE_SPEAKER: '',
  PRICE_CONFERENCE_STREAMING: '',
  active: false,
  optionals: [],
  workshops: []
}

export default function ConferenceDetail() {
    const [showSnackBar, setShowSnackBar] = useState(false)
    const [feedbackMessage, setFeedbackMessage] = useState("")
    const { id } = useParams();

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: defaultState,
      validationSchema: validationSchema,
      onSubmit:(values) => { 
        save(values)
      }
    });

    const save = async (values) => {
      const {PRICE_CONFERENCE_MEMBER,PRICE_CONFERENCE_EXPERT,PRICE_CONFERENCE_GUESTS, PRICE_CONFERENCE_SPEAKER, PRICE_CONFERENCE_STREAMING, ...conferenceValues} = values;
      const resConference = await setConferenceDetail({...conferenceValues, date_start: format(new Date(conferenceValues.date_start), 'yyyy-MM-dd'),  date_end: format(new Date(conferenceValues.date_end), 'yyyy-MM-dd')})
      const resPrices = await setPrices({
        PRICE_CONFERENCE_MEMBER: values.PRICE_CONFERENCE_MEMBER * 100,
        PRICE_CONFERENCE_EXPERT: values.PRICE_CONFERENCE_EXPERT * 100,
        PRICE_CONFERENCE_GUESTS: values.PRICE_CONFERENCE_GUESTS * 100,
        PRICE_CONFERENCE_SPEAKER: values.PRICE_CONFERENCE_SPEAKER * 100,
        PRICE_CONFERENCE_STREAMING: values.PRICE_CONFERENCE_STREAMING * 100,
      })
      if (resConference && resPrices) {
        setFeedbackMessage("Conferenza Salvata")
        setShowSnackBar(true)
      } else {
        setFeedbackMessage("Qualcosa è andato storto")
        setShowSnackBar(true)
      }
    }

    const fileChange = async (e) => {
      handleFileUpload(e, res => formik.setFieldValue('image', res))
    }

    const addOptional = () => {
      const optionals = [...formik.values.optionals]
      optionals.push(defaultOptional);

      formik.setFieldValue('optionals', optionals)
    }

    const removeOptional = (optionalIndex) => {
      const optionals = formik.values.optionals.filter((_, index) => index !== optionalIndex)
      formik.setFieldValue('optionals', optionals)
    }

    const addWorkshop = () => {
      const workshops = [...formik.values.workshops]
      workshops.push(defaultWorkshop);

      formik.setFieldValue('workshops', workshops)
    }

    const removeWorkshop = (optionalIndex) => {
      const workshops = formik.values.workshops.filter((_, index) => index !== optionalIndex)
      formik.setFieldValue('workshops', workshops)
    }

    useEffect(() => {

      async function fetchData() {
        if (id) {
          const res = await getConferenceDetail(id)
          if (res) {
            formik.setValues(res)
          }
        }

        const prices = await getPrices()
        if (prices) {
          formik.setFieldValue('PRICE_CONFERENCE_MEMBER', prices.PRICE_CONFERENCE_MEMBER.price / 100)
          formik.setFieldValue('PRICE_CONFERENCE_EXPERT', prices.PRICE_CONFERENCE_EXPERT.price / 100)
          formik.setFieldValue('PRICE_CONFERENCE_GUESTS', prices.PRICE_CONFERENCE_GUESTS.price / 100)
          formik.setFieldValue('PRICE_CONFERENCE_STREAMING', prices.PRICE_CONFERENCE_STREAMING.price / 100)
          formik.setFieldValue('PRICE_CONFERENCE_SPEAKER', prices.PRICE_CONFERENCE_SPEAKER.price / 100)
        }
      }

      fetchData(id)
    }, []);

  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={4}>
      <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item>
              <img alt="" src={formik.values.image && `${process.env.REACT_APP_API_HOST}/api/image/${formik.values.image}`} width="100" />
              <label htmlFor={`contained-button-image`}>
                  <Input accept="image/*" id={`contained-button-image`} type="file"  onChange={(event) => fileChange(event)}/>
                  <Button variant="contained" component="span">
                    Upload Immagine
                  </Button>
                </label>
              </Item>
              <Item>
                <FormControl fullWidth>
                  <InputLabel id="streaming-label">Streaming</InputLabel>
                  <Select
                    labelId="streaming-label"
                    id="streaming"
                    name="streaming"
                    value={formik.values.streaming}
                    label="Streaming"
                    onChange={formik.handleChange}
                    error={formik.errors.streaming}
                    helperText={formik.errors.streaming}
                  >
                    <MenuItem key={"streaming.false"} value={true}>NO</MenuItem>
                    <MenuItem key={"streaming.true"} value={false}>SI</MenuItem>
                  </Select>
                </FormControl>
              </Item>
              <Item>
                  <TextField
                    required
                    name="title"
                    fullWidth
                    id="title"
                    label={"Titolo"}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.errors.title}
                    helperText={formik.errors.title}
                    multiline
                  />
              </Item>
              <Item>
                <FormControl fullWidth error={formik.errors.vat_rule}>
                  <InputLabel id="vat-label">IVA *</InputLabel>
                  <Select
                    labelId="vat-label"
                    id="vat"
                    name="vat_rule"
                    value={formik.values.vat_rule}
                    label="IVA"
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="all">IVA applicata a tutti gli utenti</MenuItem>
                    <MenuItem value="italians">IVA applicata ai soli utenti italiani</MenuItem>
                  </Select>
                  <FormHelperText>{formik.errors.vat_rule}</FormHelperText>
                </FormControl>
              </Item>
              <Item>
                <FormControl fullWidth error={formik.errors.country}>
                  <InputLabel id="country-label">Nazione *</InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    name="country"
                    value={formik.values.country}
                    label="Nazione"
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="italy">Italia</MenuItem>
                    <MenuItem value="abroad">Estero</MenuItem>
                  </Select>
                  {formik.errors.country && <FormHelperText>{formik.errors.country}</FormHelperText>}
                </FormControl>
              </Item>
              <Item>
                  <TextField
                    required
                    name="description"
                    fullWidth
                    id="description"
                    label={"Descrizione"}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.errors.description}
                    helperText={formik.errors.description}
                    multiline
                  />
              </Item>
              <Item>
                  <DesktopDatePicker
                    label="Data inizio"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.date_start}
                    onChange={(value) => formik.setFieldValue('date_start', value)}
                    renderInput={(params) => <TextField {...params} error={formik.errors.date_start} helperText={formik.errors.date_start} />}
                    error={formik.errors.date_start}
                  />
                </Item>
                <Item>
                  <DesktopDatePicker
                    label="Data fine"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.date_end}
                    onChange={(value) => formik.setFieldValue('date_end', value)}
                    renderInput={(params) => <TextField {...params} error={formik.errors.date_end} helperText={formik.errors.date_end} />}
                    
                  />
              </Item>
              <Item>
                  <TextField
                    required
                    name="price"
                    fullWidth
                    id="price"
                    label={"Prezzo Intero"}
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={formik.errors.price}
                    helperText={formik.errors.price}
                  />
              </Item>
              <Item>
                  <TextField
                    required
                    name="PRICE_CONFERENCE_MEMBER"
                    fullWidth
                    id="PRICE_CONFERENCE_MEMBER"
                    label={"Prezzo Membri"}
                    value={formik.values.PRICE_CONFERENCE_MEMBER}
                    onChange={formik.handleChange}
                    error={formik.errors.PRICE_CONFERENCE_MEMBER}
                    helperText={formik.errors.PRICE_CONFERENCE_MEMBER}
                  />
              </Item>
              <Item>
                  <TextField
                    required
                    name="PRICE_CONFERENCE_EXPERT"
                    fullWidth
                    id="PRICE_CONFERENCE_EXPERT"
                    label={"Prezzo Esperti"}
                    value={formik.values.PRICE_CONFERENCE_EXPERT}
                    onChange={formik.handleChange}
                    error={formik.errors.PRICE_CONFERENCE_EXPERT}
                    helperText={formik.errors.PRICE_CONFERENCE_EXPERT}
                  />
              </Item>
              <Item>
                  <TextField
                    required
                    name="PRICE_CONFERENCE_GUESTS"
                    fullWidth
                    id="PRICE_CONFERENCE_GUESTS"
                    label={"Prezzo Ospiti"}
                    value={formik.values.PRICE_CONFERENCE_GUESTS}
                    onChange={formik.handleChange}
                    error={formik.errors.PRICE_CONFERENCE_GUESTS}
                    helperText={formik.errors.PRICE_CONFERENCE_GUESTS}
                  />
              </Item>
              <Item>
                  <TextField
                    required
                    name="PRICE_CONFERENCE_STREAMING"
                    fullWidth
                    id="PRICE_CONFERENCE_STREAMING"
                    label={"Prezzo Streaming"}
                    value={formik.values.PRICE_CONFERENCE_STREAMING}
                    onChange={formik.handleChange}
                    error={formik.errors.PRICE_CONFERENCE_STREAMING}
                    helperText={formik.errors.PRICE_CONFERENCE_STREAMING}
                  />
              </Item>
              <Item>
                  <TextField
                    required
                    name="PRICE_CONFERENCE_SPEAKER"
                    fullWidth
                    id="PRICE_CONFERENCE_SPEAKER"
                    label={"Prezzo Speaker"}
                    value={formik.values.PRICE_CONFERENCE_SPEAKER}
                    onChange={formik.handleChange}
                    error={formik.errors.PRICE_CONFERENCE_SPEAKER}
                    helperText={formik.errors.PRICE_CONFERENCE_SPEAKER}
                  />
              </Item>
              <Item>
                <FormControlLabel control={<Switch checked={formik.values.active ? true : false} onChange={(event) => {
                  formik.setFieldValue('active', !formik.values.active)
                  }} inputProps={{ name: 'active', 'aria-label': 'controlled' }}  />} label="Active" />
              </Item>
            </Grid>
          </Grid>

          <Stack direction={'row'} spacing={4} alignItems="center">
            <p>Optionals</p>
            <Fab color="primary" aria-label="add" onClick={addOptional} size="small">
              <AddIcon  />
            </Fab>
          </Stack>
          {formik.values.optionals.map((optional, optionalIndex) => 
            <Stack direction={'row'} spacing={4} alignItems="center">
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextField
                    required
                    name={`optionals[${optionalIndex}].title`}
                    fullWidth
                    id={`optionals[${optionalIndex}].title`}
                    label={"Titolo"}
                    value={optional.title}
                    onChange={formik.handleChange}
                    error={formik.errors.optionals && formik.errors.optionals[optionalIndex] && formik.errors.optionals[optionalIndex].title}
                    helperText={formik.errors.optionals && formik.errors.optionals[optionalIndex] && formik.errors.optionals[optionalIndex].title}
                    multiline
                  />
                  </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextField
                    required
                    name={`optionals[${optionalIndex}].price`}
                    fullWidth
                    id={`optionals[${optionalIndex}].price`}
                    label={"Prezzo"}
                    value={optional.price}
                    onChange={formik.handleChange}
                    error={formik.errors.optionals && formik.errors.optionals[optionalIndex] && formik.errors.optionals[optionalIndex].price}
                    helperText={formik.errors.optionals && formik.errors.optionals[optionalIndex] && formik.errors.optionals[optionalIndex].price}
                  />
                  </Item>
              </Grid>
            </Grid>
            <Fab color="secondary" aria-label="delete" onClick={() => removeOptional(optionalIndex)} size="small">
              <DeleteIcon  />
            </Fab>
            </Stack>
          )}

          <Stack direction={'row'} spacing={4} alignItems="center">
            <p>Workshops</p>
            <Fab color="primary" aria-label="add" onClick={addWorkshop} size="small">
              <AddIcon  />
            </Fab>
          </Stack>
          {formik.values.workshops.map((workshop, workshopIndex) => 
            <Stack direction={'row'} spacing={4} alignItems="center">
              <Grid container spacing={2}> 
                <Grid item sm={6}>
                  <Item>
                    <TextField
                        required
                        name={`workshops[${workshopIndex}].title`}
                        fullWidth
                        id={`workshops[${workshopIndex}].title`}
                        label={"Titolo"}
                        value={workshop.title}
                        onChange={formik.handleChange}
                        error={formik.errors.workshops && formik.errors.workshops[workshopIndex] && formik.errors.workshops[workshopIndex].title}
                        helperText={formik.errors.workshops && formik.errors.workshops[workshopIndex] && formik.errors.workshops[workshopIndex].title}
                        multiline
                      />
                  </Item>
                </Grid>
              </Grid>
              <Fab color="secondary" aria-label="delete" onClick={() => removeWorkshop(workshopIndex)} size="small">
                <DeleteIcon  />
              </Fab>
            </Stack>
          )}
          
      </div>
      <Button variant='contained' type="submit" onClick={formik.handleSubmit}>Save</Button>
    </Stack>
  </LocalizationProvider>
  <Feedback
    open={showSnackBar}
    handleClose={() => setShowSnackBar(false)}
    message={feedbackMessage}
  />
  </>
}
