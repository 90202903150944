import React, { useState, useContext } from "react"
export const FiltersContext = React.createContext();

const defaultFilters = {};

export const FiltersProvider = ({ children, filters }) => {
  const [currentFilters, setCurrentFilters] = useState(
    filters || defaultFilters
  );

  const saveFilters = (values) => {
    setCurrentFilters(values)
  };

  return (
    <FiltersContext.Provider value={{ filters: currentFilters, saveFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};

export const FiltersConsumer = FiltersContext.Consumer;

export function useFilters() {
    const context = useContext(FiltersContext);
    return context;
};