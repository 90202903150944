import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import { deleteConference, getConferences } from '../../../utils/conferencesApi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Title from '../../../components/Title';
import { IconButton } from '@mui/material';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

export default function ConferencesList() {
    const [conferences, setConferences] = useState([])
    const [itemToDelete, setItemToDelete] = useState(null)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const navigate = useNavigate()

    const askToDelete = (id) => {
      setShowDeleteConfirmation(true)
      setItemToDelete(id)
    }

    const confirmDelete = async () => {
      const res = await deleteConference(itemToDelete)
      if (res) fetchData()
      resetDelete()
    }

    const resetDelete = () => {
      setShowDeleteConfirmation(false)
      setItemToDelete(null)
    }

    const fetchData = async () => {
      const res = await getConferences()
      if (res) setConferences(res)
  }

    useEffect(() => {
      fetchData()
    }, []);

    return (
        <React.Fragment>
          <Title>Conferences</Title>
          <div>
            <Button variant='contained' onClick={() => navigate("/conference/new")} >Aggiungi Conferenza</Button>
          </div>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Titolo</TableCell>
                <TableCell>Stato</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {conferences.map((row) => (
                <TableRow key={row.id}>
                  <TableCell><Link to={`/conference/${row.id}`}>{row.title}</Link></TableCell>
                  <TableCell><Link to={`/conference/${row.id}`}>{row.active ? "Attiva" : "Non attiva"}</Link></TableCell>
                  <TableCell><Link to={`/orders/${row.id}`}>Ordini</Link></TableCell>
                  <TableCell><IconButton onClick={() => askToDelete(row.id)}><DeleteIcon  /></IconButton></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <ConfirmationDialog title={"Confermi la cancellazione?"} content={""} open={showDeleteConfirmation} handleClose={resetDelete} handleConfirm={confirmDelete} />
        </React.Fragment>
      );
}
