import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom'
import { format } from 'date-fns';
import { deleteUser, getUsers } from '../../../utils/usersApi';
import Title from '../../../components/Title';
import { Chip } from '@mui/material';
import { IconButton } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../../components/ConfirmationDialog';


export default function UsersList() {
    const [users, setUsers] = useState([])
    const [itemToDelete, setItemToDelete] = useState(null)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const navigate = useNavigate()

    const fetchData = async () => {
      const res = await getUsers()
        if (res){
          const usersResult = res.sort((a, b) => a.surname.toLowerCase() > b.surname.toLowerCase() ? 1:-1) 
          setUsers(usersResult)
        } 
    }

    const askToDelete = (id) => {
      setShowDeleteConfirmation(true)
      setItemToDelete(id)
    }

    const confirmDelete = async () => {
      const res = await deleteUser(itemToDelete)
      if (res) fetchData()
      resetDelete()
    }

    const resetDelete = () => {
      setShowDeleteConfirmation(false)
      setItemToDelete(null)
    }

    useEffect(() => {
      fetchData()
    }, []);

    return (
        <React.Fragment>
          <Title>Utenti</Title>

          <DataGrid
            autoHeight
            columns={[
              { field: 'email', headerName:'Email', width: 200 },
              { field: 'surname', headerName:'Cognome', width: 100 },
              { field: 'name', headerName:'Nome', width: 100 },
              { field: 'profile_name', 
              type: 'singleSelect',
              valueOptions: [{ value: 'client', label: 'Member' }, { value: 'expert', label: 'Expert' }, { value: 'admin', label: 'Admin' }] ,
              headerName:'Profilo', width: 100,
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return row.profile_name === 'client' ? 'Member' : row.profile_name.charAt(0).toUpperCase() + row.profile_name.slice(1).toLowerCase(); 
                }
              },
              { field: 'status', headerName:'Stato', width: 100, 
              renderCell: (cellValues) => {
                const {row} = cellValues
                return <Chip label={row.status} />
              }},
              { field: 'membership', headerName:'Membership', width: 100, 
              type: 'singleSelect',
              valueOptions: [{ value: true, label: 'Attiva' }, { value: false, label: 'Non Attiva' }],
              renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <Chip label={row.membership ? 'Attiva' : 'Non Attiva'} />
                } 
              },
              { field: 'subscription_date_end', headerName:'Scadenza Membership', width: 100, 
                type: "date",
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  if (row.subscription_date_end) return format(new Date(row.subscription_date_end), 'dd/MM/yyyy')
                  if (row.subscription_trial_end) return format(new Date(row.subscription_trial_end), 'dd/MM/yyyy')
                } 
              },
              { field: 'detail', headerName:'', width: 50, 
                filterable: false,
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <IconButton variant="contained" onClick={() => navigate(`/users/${row.id}`)}>
                    <EditIcon />
                  </IconButton>
                } 
              },
              { field: 'delete', headerName:'', width: 50, 
                filterable: false,
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <IconButton variant="contained" onClick={() => askToDelete(row.id)}>
                    <DeleteIcon />
                  </IconButton>
                }
              }  
            ]}
            getRowId={(row) => row.id}
            rows={users}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellClick={(e) => console.log(e)}
          />
          <ConfirmationDialog title={"Confermi la cancellazione?"} content={""} open={showDeleteConfirmation} handleClose={resetDelete} handleConfirm={confirmDelete} />
        </React.Fragment>
      );
}
