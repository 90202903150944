import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom'
import { deleteSection, getDocuments } from '../../../utils/api';
import { Button, IconButton } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import Title from '../../../components/Title';
import { useFilters } from '../../../context/FiltersContext';


export default function DocumentsList() {
    const [documents, setDocuments] = useState([])
    const [itemToDelete, setItemToDelete] = useState(null)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const {filters, saveFilters} = useFilters()

    const navigate = useNavigate()

    const fetchData = async () => {
      const res = await getDocuments()
        if(res){
          const documentsResult = res.sort((a, b) =>{
            if(a.category_name === b.category_name){
              return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            }
            return 0;
          })
          setDocuments(documentsResult)
        } 
    }

    const askToDelete = (id) => {
      setShowDeleteConfirmation(true)
      setItemToDelete(id)
    }

    const confirmDelete = async () => {
      const res = await deleteSection(itemToDelete)
      if (res) fetchData()
      resetDelete()
    }

    const resetDelete = () => {
      setShowDeleteConfirmation(false)
      setItemToDelete(null)
    }

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <React.Fragment>
          <Title>Documents</Title>
          <div>
            <Button variant='contained' onClick={() => navigate("/document/new")} >Aggiungi Sezione</Button>
          </div>
          <DataGrid
            autoHeight
            onPageChange={(data) => saveFilters({documents: {...filters.documents, pagination: {page: data}}})}
            onFilterModelChange={(data) => saveFilters({documents: {...filters.documents, filter: {filterModel: data}}})}
            initialState={filters.documents && filters.documents}
            columns={[
              { field: 'category_name', headerName:'Categoria', width: 150 },
              { field: 'title', headerName:'Titolo', width: 150 },
              { field: 'detail', headerName:'', width: 50, 
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <IconButton variant="contained" onClick={() => navigate(`/document/${row.id}`)}>
                    <EditIcon />
                  </IconButton>
                } 
              },
              { field: 'delete', headerName:'', width: 50, 
                renderCell: (cellValues) => {
                  const {row} = cellValues
                  return <IconButton variant="contained" onClick={() => askToDelete(row.id)}>
                    <DeleteIcon />
                  </IconButton>
                } 
              }
            ]}
            getRowId={(row) => row.id}
            rows={documents}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellClick={(e) => console.log(e)}
          />
          <ConfirmationDialog title={"Confermi la cancellazione?"} content={""} open={showDeleteConfirmation} handleClose={resetDelete} handleConfirm={confirmDelete} />
        </React.Fragment>
      );
}
