import axios from "axios"

export async function getConferences() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/conferences`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.conferences
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getConferenceDetail(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/conferences/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return {...res.data.conference, price: res.data.conference.price / 100, optionals: res.data.conference.optionals.map(optional => { return {...optional, price: optional.price / 100}})}
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function setConferenceDetail(conference) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/conferences${conference.id ? `/${conference.id}` : ''}`, {
            method: conference.id ? "PUT" : "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            data: {conference: {...conference, price: conference.price * 100, optionals: conference.optionals.map(optional => { return {...optional, price: optional.price * 100}})}}
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function deleteConference(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/conferences/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}