import React, { useState, useContext } from "react"
import { doAuth } from "../utils/api";
export const UserContext = React.createContext();

const defaultUser = {};

export const UserProvider = ({ children, user }) => {
  const [currentUser, setCurrentUser] = useState(
    user || defaultUser
  );

  const saveUser = (values) => {
    setCurrentUser(values)
  };

  const isLogged = () => {
    const token = localStorage.getItem('token')
    return Object.keys(currentUser).length > 0 && token !== null
  }
  const isMember = () => isLogged() && currentUser.membership 

  const doLogin = async (user, password) => {
    // Call login API
    const res = await doAuth(user, password)
    setCurrentUser(res)
  }

  return (
    <UserContext.Provider value={{ user: currentUser, saveUser, isLogged: isLogged(), isMember: isMember(), doLogin }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;

export function useUser() {
    const context = useContext(UserContext);
    return context;
};