import axios from "axios"

export async function getPrices() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/prices`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.data
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function setPrices(data) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/prices`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            data: {data}
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}