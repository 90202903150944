import React, { useEffect, useState } from "react";
import {
  getManualTransfers,
  confirmManualTransfer,
  deleteManualTransfer,
} from "../../utils/manualTransfersApi";
import Title from "../../components/Title";
import { IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";



export default function ManualTransfersList() {
  const [manualTransfers, setManualTransfers] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [transferToDelete, setTransferToDelete] = useState(null);
  const [transferToConfirm, setTransferToConfirm] = useState(null);


  const confirm = async (id) => {
    await confirmManualTransfer(id);
    await fetchData();
    setOpenConfirmModal(false)
    setTransferToConfirm(null)
  };

  const deleteTransfer = async (id) => {
    await deleteManualTransfer(id);
    await fetchData();
    setOpenDeleteModal(false);
    setTransferToDelete(null)
  };

  function getReadyToConfirmTransfer(id){
    setOpenConfirmModal(true);
    setTransferToConfirm(id)
  }

  function closeConfirmModal(){
    setOpenConfirmModal(false)
    setTransferToConfirm(null)
  }

  function getReadyToDeleteTransfer(id) {
    setOpenDeleteModal(true);
    setTransferToDelete(id);
  }

  function closeDeleteModal(){
    setOpenDeleteModal(false)
    setTransferToDelete(null)
  }

  const fetchData = async () => {
    const res = await getManualTransfers();
    if (res) setManualTransfers(res);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Title>Bonifici in Attesa</Title>

      <DataGrid
        autoHeight
        columns={[
          { field: "id", headerName: "Identificativo Bonifico", width: 100 },
          { field: "email", headerName: "Email", width: 250 },
          { field: "type", headerName: "Tipologia Acquisto", width: 250 },
          {
            field: "data.id",
            headerName: "Identificativo",
            width: 250,
            renderCell: (cellValues) => {
              const { row } = cellValues;
              if (row.type === "MEMBERSHIP")
                return (
                  <Link to={`/memberships/${row.data.id}`}>Membership</Link>
                );
              if (row.type === "CONFERENCE")
                return (
                  <Link to={`/order/${row.data.id}`}>Ordine Conferenza</Link>
                );
            },
          },
          {
            field: "data.order",
            headerName: "Causale",
            width: 100,
            valueGetter: (cellValues) => {
              return cellValues.row.data.order;
            },
          },
          {
            field: "data.amount",
            headerName: "Dovuto",
            width: 100,
            valueGetter: (cellValues) => {
              return cellValues.row.data.amount / 100 + " €";
            },
          },
          {
            field: "confirm",
            headerName: "Stato",
            width: 100,
            type: "singleSelect",
            valueOptions: ["Confermato", "Non confermato"],
            valueGetter: (cellValues) => {
              return cellValues.row.data.confirm
                ? "Confermato"
                : "Non confermato";
            },
          },
          {
            field: "confirm",
            headerName: "",
            width: 50,
            filterable: false,
            renderCell: (cellValues) => {
              const { row } = cellValues;
              return (
                <IconButton variant="contained" onClick={() => getReadyToConfirmTransfer(row.id)}>
                  <CheckIcon />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: "",
            width: 50,
            filterable: false,
            renderCell: (cellValues) => {
              const { row } = cellValues;
              return (
                <IconButton
                  variant="contained"
                  onClick={() => getReadyToDeleteTransfer(row.id)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              );
            },
          },
        ]}
        getRowId={(row) => row.id}
        rows={manualTransfers}
        components={{
          Toolbar: GridToolbar,
        }}
        onCellClick={(e) => console.log(e)}
      />
      {/* cancel modal */}
      {openDeleteModal && (
        <div className="overlay">
          <div className="delete__modal">
            <CloseIcon onClick={closeDeleteModal} />
            Confermi l'eliminazione di questo bonifico?
            <div className="button__flex">
              <Button
                variant="contained"
                onClick={() => deleteTransfer(transferToDelete)}
              >
                Elimina
              </Button>
              <Button variant="outlined" onClick={closeDeleteModal}>
                Torna indietro
              </Button>
            </div>
          </div>
        </div>
      )}
      {/* confirm modal */}
      {openConfirmModal && (
        <div className="overlay">
          <div className="delete__modal">
            <CloseIcon onClick={closeConfirmModal} />
            Sei sicuro di voler confermare il pagamento?
            <div className="button__flex">
              <Button
                variant="contained"
                onClick={() => confirm(transferToConfirm)}
              >
                Conferma
              </Button>
              <Button variant="outlined" onClick={closeConfirmModal}>
                Torna indietro
              </Button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
