import { Box, Button, Chip, Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import { getOrderDetail } from '../../../utils/ordersApi';
import { format } from 'date-fns';

const style = {
    '@media print': {
        position: "absolute",
        top: 0,
        left: 0,
      width: "100vw",
      height: "100vh",
      pageBreakAfter: "always",
      zIndex: 99999,
      background: "white",
        '.printButton' : {
            display: 'none'
        }
    }
  };
  
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    position: 'relative'
}));

const ResultsItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  position: 'relative',
  background: 'none'
}));

export default function OrderDetail() {
    const params = useParams();
    const [currentDetail, setCurrentDetail] = useState()
    const [conferenceDetail, setConferenceDetail] = useState()


    useEffect(() => {
        console.log(params)
        async function fetchData() {
          if (params.orderId) {
            const res = await getOrderDetail(params.orderId)
            if (res) {
              setCurrentDetail(res)
              setConferenceDetail(res.conference)
            }
          }
        }
  
        fetchData(params.orderId)
      }, [params]);
  return (
    currentDetail ? <Box sx={{ ...style }}>
        <Button className="printButton" variant="contained" onClick={() => window.print()}> PRINT</Button>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', color: 'grey'}}>
          <h2>Ordine #{currentDetail.order_id}</h2>
          {conferenceDetail && <h3>{conferenceDetail.title}</h3>}
        </div>

        <hr />

        {currentDetail.company && <>
          <h2 style={{color: 'grey'}}>{currentDetail.company.name}</h2>
          <div style={{display:'flex', justifyContent:'space-between', marginBottom: 30}}>
            <div>
                <p>{currentDetail.company.address} - {currentDetail.company.postal_code}- {currentDetail.company.city}</p>
                <p>{currentDetail.company.country}</p>
            </div>
            <div style={{textAlign: 'right'}}>
              {currentDetail.created_at && <p>Data Ordine: {format(new Date(currentDetail.created_at), 'dd/MM/yyyy')}</p>}
              {currentDetail.paid_at && <p>Data Pagamento: {format(new Date(currentDetail.paid_at), 'dd/MM/yyyy')}</p>}
            </div>
          </div>
        </>}

        {currentDetail.partecipants && currentDetail.partecipants.map(participant => <>
        <Stack spacing={2}>
        
            <Grid container>
              <Grid item md={6}>
                <Item elevation={1}>
                  <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', color: 'grey'}}>
                    <h3>{participant.name} {participant.surname}</h3>
                    <Chip label={participant.company_founder ? 'Member' : 'Additional Member'} style={{fontSize: 11}} />
                  </div>
                  
                  <p>{participant.email}</p>
                  <p>{participant.phone}</p>
                  
                </Item>
                
              </Grid>
              <Grid item md={6}>
                <Item elevation={1}>
                  <h3 style={{color: 'grey'}}>Order Summary</h3>
                  <Grid container>
                    {participant.optionals && participant.optionals.map(optional =>
                    <Grid container >
                        <Grid item md={6}>
                        <p><b>{optional.title}</b></p>
                        </Grid>
                        <Grid item md={3}>
                        <p>{optional.quantity}</p>
                        </Grid>
                        <Grid item md={3} style={{textAlign:'right'}}>
                        <p>{(optional.price * optional.quantity) / 100} €</p>
                        </Grid>
                    </Grid>)}
                    {participant.workshops && participant.workshops.map(workshop =>
                        <Grid container>
                          <Grid item md={6}>
                            <p><b>{workshop.title}</b></p>
                          </Grid>
                          <Grid item md={3}>
                          <p>1</p>
                          </Grid>
                          <Grid item md={3}>
                              
                          </Grid>
                      </Grid>)}
                  </Grid>
                </Item>
              </Grid>
            </Grid>


            
        </Stack>
        </>)}

        <hr />
        <Grid container>
          <Grid item md={6}></Grid>
          <Grid item md={6}>
            <ResultsItem elevation={0}>
              <Grid container >
                  <Grid item md={6}>
                    <p><b>Coupon</b></p>
                  </Grid>
                  <Grid item md={6} style={{textAlign:'right'}}>
                    <p>{currentDetail.coupon_code}</p>
                  </Grid>

                  <Grid item md={6}>
                    <p><b>Sconto</b></p>
                  </Grid>
                  <Grid item md={6} style={{textAlign:'right'}}>
                    <p>{currentDetail.discount}</p>
                  </Grid>

                  <Grid item md={6}>
                    <p><b>Total</b></p>
                  </Grid>
                  <Grid item md={6} style={{textAlign:'right'}}>
                    <p>{(currentDetail.price / 100)} €</p>
                  </Grid>
              </Grid>
            </ResultsItem>
          </Grid>
        </Grid>
        
    </Box> : <div></div>
  )
}
