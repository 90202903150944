import axios from 'axios'
import { format } from 'date-fns'

export async function doAuth(email, password) {
    const res = await axios(`${process.env.REACT_APP_API_HOST}/api/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: {email, password}
    })

    // result.data should contain the jwt
    // store the token into localStorage or a COOKIE
    console.log(res)
    if (res.data.status === "success") {
        localStorage.setItem('token', res.data.data.auth_token)
        return res.data
    }
    return false
}

export async function checkAuth(token) {
    const res = await axios(`${process.env.REACT_APP_API_HOST}/api/auth/check-token`, {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
    })
    
    if (res.data.status === "success") {
        return res.data.data
    }
    return false
}

export async function getCategories() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/categories`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.data
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getExperts() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/experts`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.experts
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getLanguages() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/languages`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.data
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getDocuments() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/sections`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.sections
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getDocumentDetail(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/sections/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.section
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getJudicialDocumentDetail(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/judicial_document/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getJudicialDocuments() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/judicial_documents`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.judicial_documents
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function saveJudicialDocument(document) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/judicial_documents`, {
            method: "POST", // TODO: gestire PUT se fixato
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            data: { judicial_document: document },
        })
        if (res.data.status === "success") {
            return res.data.judicial_documents
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function saveJudicialText(text) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/judicial_texts`, {
            method: "POST", // TODO: gestire PUT se fixato
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            data: { judicial_text: text },
        })
        if (res.data.status === "success") {
            return res.data.judicial_texts
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function deleteJudicialDocument(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/judicial_documents/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function deleteJudicialTexts(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/judicial_texts/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getJudicialTexts() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/judicial_texts`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.judicial_texts
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}


export async function saveDocumentDetail(data) {
    try {
        const token = localStorage.getItem('token')
        await axios(`${process.env.REACT_APP_API_HOST}/api/sections${data.id ? `/${data.id}` : ``}`, {
            method: data.id ? "PUT" : "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            data: {section: data}
        })
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function deleteSection(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/sections/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function deleteDocument(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/documents/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        })
        if (res.data.status === "success") {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function uploadFile(file) {
    const token = localStorage.getItem('token')

    const fd = new FormData();
    fd.append('file', file);

    try {

        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
            method: "POST",
            headers: { 'Authorization': `Bearer ${token}` },
            body: fd
        })

        const result = await res.json()

        if (result.status === "success") {
            return result.file
        }
        
        return false

    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getCountries() {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/countries`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.countries
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}


export async function exportCsv(exportObject) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/csv/download-payments`, {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            data: {data: {date_start: format(new Date(exportObject.date_start), 'yyyy-MM-dd'), date_end: format(new Date(exportObject.date_end), 'yyyy-MM-dd')}},
            responseType: 'blob'
        })
        if (res.data) {

            const file = new Blob(
                [res.data], 
                {type: 'text/csv'});

            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);

            return res
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}